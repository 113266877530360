<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="blue"
      spinner="mini-spinner"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showsnackbar" color="#437ccc" right timeout="3000">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap pb-3>
      <v-flex xs6>
        <h3 class="bold">MANGE SNARE</h3>
      </v-flex>
      <v-flex xs6 text-right>
        <v-btn color="primary" dark dense @click="myDialog = true">
          Add Snare <v-icon x-small>mdi-plus</v-icon>
        </v-btn>
      </v-flex>
    </v-layout>
    <v-layout wrap v-if="allData.length > 0">
      <v-flex xs12 sm4 md3 pa-1 v-for="(item, i) in allData" :key="i">
        <v-card elevation="4">
          <v-layout wrap pa-3>
            <v-flex xs3>
              <v-avatar v-if="item.icon.length > 0">
                <v-img contain :src="mediaURL + item.icon"> </v-img>
              </v-avatar>

              <v-avatar v-else color="grey">
                <span style="font-size: 8px; color: white"> No Image</span>
              </v-avatar>
            </v-flex>
            <v-flex xs9>
              <span style="font-family: pregular">
                <b> {{ item.name }} </b></span
              >
              <br />
              <span style="font-size: 12px; font-family: pregular"
                >{{ item.categoryType }}
              </span>
            </v-flex>

            <!-- <v-flex xs12>
            
            </v-flex> -->

            <v-flex xs12 pt-1>
              <v-divider></v-divider>
            </v-flex>
            <v-flex xs12 text-right pt-1>
              <v-btn
                dark
                text
                color="error"
                x-small
                @click="(deleteDialog = true), (deleteId = item._id)"
              >
                delete
              </v-btn>
              <v-btn
                class="ml-1"
                text
                color="primary"
                x-small
                @click="
                  (editDialog = true),
                    ((Editname = item.name),
                    (EditType = item.categoryType),
                    (EditIcon = item.icon[0]),
                    (editId = item._id))
                "
              >
                edit
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout wrap v-else>
      <v-flex xs12 text-center>
        <h4>No Data Available......</h4>
      </v-flex>
    </v-layout>

    <v-dialog width="500px" v-model="deleteDialog">
      <v-card width="500px">
        <v-layout wrap pa-5>
          <v-flex xs12>
            <h4>Are you sure you want to remove this Snare</h4>
          </v-flex>
          <v-flex xs12 text-right>
            <v-btn x-small text color="error" @click="deleteDialog = false">
              close</v-btn
            >
            <v-btn
              class="ml-1"
              text
              x-small
              color="primary"
              @click="deleteDivision()"
            >
              remove
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>

    <v-dialog width="500px" v-model="myDialog">
      <v-card width="500px">
        <v-layout wrap pa-5>
          <v-flex xs12>
            <v-select
              label="Choose Type"
              outlined
              dense
              :items="TYPES"
              v-model="Mytype"
            >
            </v-select>
          </v-flex>
          <v-flex xs12>
            <v-text-field label="Snare Name" outlined dense v-model="name">
            </v-text-field>
          </v-flex>
          <v-flex xs12>
            <IconUpload
              @stepper="winStepper"
              typr
              :height="1"
              :width="1"
              :heading="'Upload icon Image'"
              :componentType="'iconImage'"
            />
          </v-flex>
          <v-flex xs12 text-right>
            <v-btn x-small color="primary" @click="addCategory()"> Save </v-btn>
            <v-btn class="ml-1" x-small color="error" @click="myDialog = false">
              close</v-btn
            >
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-dialog width="500px" v-model="editDialog">
      <v-card width="500px">
        <v-layout wrap pa-5>
          <v-flex xs12>
            <v-text-field label="Snare Name" outlined dense v-model="Editname">
            </v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-text-field label="Choose Type" outlined dense v-model="EditType">
            </v-text-field>
          </v-flex>
          <v-flex xs12>
             <IconUpload
            @stepper="winStepper"
            :singleImage="EditIcon"
            typr
            :height="1"
            :width="1"
            :heading="'Upload icon Image'"
            :componentType="'iconImage'"
          />
          </v-flex>
         

          <v-flex xs12 text-right>
            <v-btn x-small color="error" @click="editDialog = false">
              close</v-btn
            >
            <v-btn
              class="ml-1"
              x-small
              color="primary"
              @click="EditDivisions()"
            >
              Save
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="dialog" width="70%">
      <v-card>
        <v-toolbar height="30px" dense dark>
          <v-spacer></v-spacer>
          <v-btn right icon dark @click="(imagedata = null), (dialog = false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-title class="text-h5 grey lighten-2"> Document </v-card-title>

        <!-- <v-card-text v-if="doc.split('.').pop() == 'pdf'">
          <iframe
            v-if="doc"
            :src="baseURL + '/' + doc"
            height="700px"
            width="900px"
            contain
          >
          </iframe>
        </v-card-text> -->
        <v-card-text v-if="imagedata">
          <v-img :src="mediaURL + imagedata"></v-img>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            small
            color="red"
            text
            @click="(dialog = false), (imagedata = null)"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import IconUpload from "../../common/uploadIcon";
export default {
  components: {
    IconUpload,
  },
  data() {
    return {
      Editname: null,
      EditType: null,
      Mytype: null,
      dialog: false,
      imagedata: null,
      deleteDialog: false,
      deleteId: null,
      editId: null,
      editDialog: false,
      Editdivision: "",
      showsnackbar: false,
      msg: "",
      appLoading: false,
      ServerError: false,
      myDialog: false,
      name: "",
      allData: [],
      documentFile1: null,
      docId: null,
      doc: new FormData(),
      
      EditIcon:null,
      TYPES: [],
      iconImage: null,
    };
  },

  mounted() {
    this.getData();
    this.getTypes();
  },

  methods: {
    winStepper(window_data) {
      if (window_data.type == "iconImage") {
        this.iconImage = window_data.selectedFiles;
      }
    },
    getTypes() {
      this.appLoading = true;
      axios({
        url: "/get/all/types",
        method: "get",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.TYPES = response.data.id;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          //   this.ServerError = true;
          console.log(err);
        });
    },
    addIconImage(idVal) {
      this.appLoading = true;
      this.doc.append("id", idVal);
      this.doc.append("icon", this.iconImage);

      axios({
        url: "/upload/category/icon",
        method: "post",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: this.doc,
      })
        .then((response) => {
          if (response.data.status) {
            this.showsnackbar = true;
            this.msg = response.data.msg;
            this.myDialog = false;
             this.doc= new FormData();
            //  this.iconImage = null;
              this.myDialog = false;
              this.editDialog =false;
              this.Mytype =null,
              this.name = null,
            this.getData();
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    browseCover(event) {
      var got = event.target.files[0];
      this.documentFile1 = got;
      console.log("got", got);
    },

    deleteDivision() {
      axios({
        url: "/category/delete/" + this.deleteId,
        method: "post",
        // data: {
        //   id: this.deleteId,
        // },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.showsnackbar = true;
            this.msg = "Snare deleted Successfully";
            this.deleteDialog = false;
            this.getData();
          } else {
            this.showsnackbar = true;
            this.msg = "Failed to delete";
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    EditDivisions() {
      axios({
        url: "/admin/category/edit",
        method: "post",
        data: {
          id: this.editId,
          name: this.Editname,
          type: this.EditType,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
             
             if (this.iconImage != null) {
              this.addIconImage(this.editId);
            } else {
              this.showsnackbar = true;
              this.msg = "Editted Successfully";
              this.myDialog = false;
              this.editDialog = false;
              this.getData();
            }
          } else {
            this.showsnackbar = true;
            this.msg = "Can't Edit";
            this.myDialog = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getData() {
      this.appLoading = true;
      axios({
        url: "/category/list",
        method: "get",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.allData = response.data.data;
          } else {
            this.showsnackbar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    addCategory() {
      axios({
        url: "/admin/category/add",
        method: "post",
        data: {
          name: this.name,
          type: this.Mytype,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.docId = response.data.id;
            if (this.iconImage != null) {
              this.addIconImage(this.docId);
            } else {
              this.Mytype =null,
              this.name = null,
              this.showsnackbar = true;
              this.msg = response.data.msg;
              this.myDialog = false;
              this.getData();
            }
          } else {
            this.showsnackbar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
